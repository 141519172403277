import { AxiosResponse } from 'axios';
import {
  RawTimeZone,
  TimeZoneInfo,
  TimeZoneModel,
} from 'core/models/timeZoneModel';
import { setTimeZone } from 'reducers/timezone';
import { store } from 'store';

import { apiWrapper, axiosApi, ResultData } from 'utils/api';

const getTimeZoneDict = async (): Promise<AxiosResponse<
  TimeZoneModel[]
> | void> => {
  const success = async (): Promise<AxiosResponse<TimeZoneModel[]>> => {
    const response = await axiosApi().get<RawTimeZone[]>(`/timezone`);
    const tizeZoneData = response.data?.map((item) => new TimeZoneModel(item));
    if (response) {
      store.dispatch(
        setTimeZone({
          timeZone: tizeZoneData,
        }),
      );
    }
    return {
      ...response,
      data: tizeZoneData,
    };
  };

  return apiWrapper<TimeZoneModel[]>({
    success,
  });
};

export const getTimeZoneInfoV2 = async (
  timeZoneName: string,
  timestamp: number,
): Promise<AxiosResponse<TimeZoneInfo> | void> => {
  const success = async (): Promise<AxiosResponse<TimeZoneInfo>> => {
    const response = await axiosApi().get<ResultData<TimeZoneInfo>>(
      `v2/timezone/info`,
      {
        params: { timeZoneName, timestamp },
      },
    );
    const data_ = response.data.data;
    if (data_ != null) {
      data_.timeZoneDescription1 = data_.timeZoneOffsetStr;
      if (data_.isDst) {
        data_.timeZoneDescription1 = `${data_.timeZoneOffsetStr}/${data_.dstStr}`;
      } else {
        data_.timeZoneDescription1 = `${data_.timeZoneOffsetStr}/${data_.estStr}`;
      }
      data_.timeZoneDescription1 = `(${data_.timeZoneDescription1})`;
      data_.timeZoneDescription2 = `(${data_.timeZoneOffsetStr})`;
    }
    return {
      ...response,
      data: data_,
    };
  };

  return apiWrapper<TimeZoneInfo>({
    success,
  });
};

export default getTimeZoneDict;
